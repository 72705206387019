var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "scopeList" },
      [
        _vm._l(
          _vm.dataList.length > _vm.num ? _vm.num : _vm.dataList.length,
          function (i) {
            return _c(
              "div",
              { key: i, staticClass: "personCont personList" },
              [
                _c("CbAvater", {
                  attrs: {
                    "on-job-id": _vm.dataList[i - 1].onJobId,
                    "avater-name": _vm.dataList[i - 1].staffName,
                    "default-name-length": 1,
                    size: "22",
                  },
                }),
              ],
              1
            )
          }
        ),
        _c(
          "a-popover",
          {
            attrs: {
              title: "适用人员",
              placement: "bottomRight",
              trigger: "hover",
              "auto-adjust-overflow": false,
              "overlay-class-name": "thePopover",
            },
          },
          [
            _c("template", { slot: "content" }, [
              _c(
                "div",
                { staticClass: "scopeList popoverContent" },
                _vm._l(_vm.dataList, function (item) {
                  return _c(
                    "div",
                    { key: item.onJobId, staticClass: "personCont" },
                    [
                      _c("CbAvater", {
                        attrs: {
                          "on-job-id": item.onJobId,
                          "avater-name": item.staffName,
                          "default-name-length": 1,
                          size: "22",
                        },
                      }),
                    ],
                    1
                  )
                }),
                0
              ),
            ]),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.dataList.length > _vm.num,
                    expression: "dataList.length > num",
                  },
                ],
                staticClass: "num",
              },
              [_vm._v(_vm._s(_vm.dataList.length - _vm.num))]
            ),
          ],
          2
        ),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }